import { useEffect, useState } from "react";
import { client } from "../../../utils/Client";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Opportunity } from "feathers-backend";
import * as yup from "yup";

type Props = {
  opportunity: Opportunity;
};

interface OneflowIconProps {
  oneflow: {
    id: number;
    state: string;
  };
  className?: string;
}

export const OneflowIcon: React.FC<OneflowIconProps> = ({ oneflow, className }) => {
  // Color on state using a switch statement using classes and update the className string
  let color = "";
  switch (oneflow.state) {
    case "draft":
      color = "text-placehold";
      break;
    case "pending":
      color = "text-blue-500";
      break;
    case "overdue":
      color = "text-orange-500";
      break;
    case "signed":
      color = "text-green-500";
      break;
    case "declined":
      color = "text-red-500";
      break;
    default:
      color = "text-placehold";
      break;
  }

  className = `${className} ${color}`;

  return (
    <svg
      width="113"
      height="113"
      viewBox="0 0 113 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30.34 22.24H60.69V52.59H30.34V22.24ZM30.34 82.93H60.69V113.27H30.34V82.93ZM0 52.59H30.35V82.93H0V52.59ZM60.69 52.59H91.04V82.93H60.69V52.59ZM82.8799 0H113.23V30.35H82.8799V0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default function Oneflow({ opportunity }: Props) {
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const templates = await client.service("oneflow").templates();
      setTemplates(templates || []);
    };
    fetchTemplates();
  }, []); // Add an empty dependency array to run the effect only once

  const handleSelectionChange = (keys: any) => {
    const selectedId = Array.from(keys)[0]; // Assuming single selection
    const selected = templates.find((template) => template.id === Number(selectedId));
    setSelectedTemplate(selected);
  };

  const validationSchema = yup.object().shape({
    Kundnamn: yup.string().required("Kundnamn är obligatoriskt"),
    Organisationsnummer: yup.string().required("Organisationsnummer är obligatoriskt"),
    Värde: yup.number().required("Värde är obligatoriskt"),
    "Kontakt E-post": yup.string().email().required("Kontakt E-post är obligatoriskt"),
    "Kontakt Förnamn": yup.string().required("Kontakt Förnamn är obligatoriskt"),
    "Kontakt Efternamn": yup.string().required("Kontakt Efternamn är obligatoriskt"),
    Template: yup.object().nullable().required("Mall är obligatoriskt"),
  });

  const validateFields = () => {
    try {
      validationSchema.validateSync(
        {
          Kundnamn: opportunity?.customer?.name,
          Organisationsnummer: opportunity?.customer?.organizationNumber,
          Värde: opportunity?.expectedValue,
          "Kontakt E-post": opportunity?.contact?.email,
          "Kontakt Förnamn": opportunity?.contact?.firstName,
          "Kontakt Efternamn": opportunity?.contact?.lastName,
          Template: selectedTemplate,
        },
        { abortEarly: false }
      );
      setErrors([]);
      return true;
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors(err.errors);
      }
      return false;
    }
  };

  const handleCreateContract = async () => {
    const isValid = validateFields();
    if (!isValid) return;
    if (errors.length > 0) return;
    setIsLoading(true);
    try {
      const result = await client.service("oneflow").createContract({
        opportunity,
        templateId: selectedTemplate.id,
      });
      if (result.status !== 200) {
        setErrors([result.detail]);
      }
      if (result.id) {
        window.open(`https://app.oneflow.com/contracts/${result.id}`, "_blank");
      }
    } catch (error: any) {
      setErrors(["An unexpected error occurred."]);
    } finally {
      // Open the contract in a new tab

      setIsLoading(false);
    }
  };

  const handleDeleteContract = async () => {
    // Delete the opportunity.oneflow object using undefined
    const result = await client.service("oneflow").removeContract({ opportunity });
  };

  return (
    <div className="flex flex-col p-4 space-y-4 border rounded-lg border-ash">
      <div className="relative">
        <div className="w-24 mx-auto">
          <svg
            className="c-site-header__logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1098.93 241.98"
            fill="currentColor"
          >
            <path d="M592.78 46.09h30.34v193.08h-30.34zM401.43 213.49c-21.59 0-38.39-12.87-44.62-31.49l117-30.29c-5-46.76-39.32-71.13-74.64-71.13a73.48 73.48 0 00-56 25.56C330.73 120.66 324 139.88 324 161c0 49.72 35 80.44 78 80.44 28.83 0 51.2-13 63.19-30.15l-21.45-17.94c-8.74 12.86-25.87 20.14-42.31 20.14zM399 108.67c19.16 0 32.86 10.26 39.79 25.55l-84.62 21.89c.5-26.77 17.99-47.44 44.83-47.44zM242.55 80.6c-40.45 0-66 25.82-66 64.72v93.85h30.35v-93.85c0-23.29 11.38-35.94 35.63-35.94S278 122 278 145.32v93.85h30.35v-93.85c.01-38.9-25.35-64.72-65.8-64.72zM80.7 241.48A80.45 80.45 0 11161.14 161a80.53 80.53 0 01-80.44 80.48m0-132c-27.56 0-50 23.14-50 51.59s22.42 51.58 50 51.58 50-23.14 50-51.58-22.42-51.59-50-51.59M719 241.48A80.45 80.45 0 11799.44 161 80.54 80.54 0 01719 241.48m0-132c-27.56 0-50 23.14-50 51.59s22.43 51.58 50 51.58 50-23.14 50-51.58-22.42-51.59-50-51.59M520.3 114.84v-8.08c0-13.47.5-19.49 6.14-26.16S542 70.93 555 70.93a85.3 85.3 0 0119 1.77V45.39a110.43 110.43 0 00-23.12-2.25c-19.56 0-33.45 4.76-43.32 12.85-13.35 10.94-17.49 25.2-17.49 48.44v134.74h30.34V141.4l41.88-10.93V103.9zM1016.05 143.05v33.76c0 23.28-11.33 35.93-35.24 35.94-11.92 0-20.84-3.43-26.52-9.44-6.74-7.12-8.45-14.39-8.49-26.29V83h-30.34v93.86c0 13.3-2.78 20.87-8.65 26.7s-14.63 9.23-26.58 9.24c-23.63 0-35-12.73-35-35.94V83h-30.36v97.37c0 17.93 5 32.3 16.36 43.45 13 12.7 30.17 17.76 49.22 17.76 21 0 38.87-7.87 50.18-20.73 11.26 12.81 29.09 20.73 50.41 20.73 20 0 37.22-6 48.52-17.28 11.51-11.48 16.83-24.38 16.83-45.58v-35.67zM1016.04 22.24h30.35v30.35h-30.35zM1016.04 82.93h30.35v30.34h-30.35zM985.7 52.59h30.35v30.34H985.7zM1046.39 52.59h30.35v30.34h-30.35zM1068.58 0h30.35v30.35h-30.35z"></path>
          </svg>
        </div>
        {opportunity.oneflow && (
          <XMarkIcon
            onClick={handleDeleteContract}
            className="absolute top-0 right-0 w-4 h-4 cursor-pointer text-ash hover:text-line"
          />
        )}
      </div>
      {!opportunity.oneflow && (
        <>
          <Select
            items={templates}
            className="w-full"
            classNames={{
              listbox: "listbox",
            }}
            label="Mall"
            placeholder="Välj en mall"
            onSelectionChange={handleSelectionChange}
            showScrollIndicators={true}
          >
            {(template: any) => (
              <SelectItem key={template.id} value={template.id}>
                {template.name}
              </SelectItem>
            )}
          </Select>
          {errors.length > 0 && (
            <div className="text-danger">
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}
          <Button
            isDisabled={!selectedTemplate}
            isLoading={isLoading}
            onClick={handleCreateContract}
            className=""
            style={{ backgroundColor: "#ffd063", color: "#013a4c" }}
          >
            Skapa {selectedTemplate?.name ? selectedTemplate?.name : ""}
          </Button>
        </>
      )}
      {opportunity.oneflow?.id && (
        <>
          <div className="">Status: {opportunity.oneflow.state}</div>
          <Button
            type="button"
            onClick={() => window.open(`https://app.oneflow.com/contracts/${opportunity?.oneflow?.id}`, "_blank")}
            style={{ backgroundColor: "#ffd063", color: "#013a4c" }}
          >
            Öppna i Oneflow
          </Button>
        </>
      )}
    </div>
  );
}
